import { useEffect, useState } from "react";
import { fetchJWT } from "./Utils/fetchJWT";
import {Routes,Route} from "react-router-dom";
import logo from "./Images/logo.svg";
import { Accueil } from "./Pages/Accueil";
import { ScanPage } from "./Pages/ScanPage";
import { ScanResultat } from "./Pages/ScanResultat";

import { PointDeCollecteData } from "./Components/PointDeCollecteData";

export const MyRoutes = () => {
    const [error, setError] = useState()

    useEffect(() => {
        fetchJWT(setError)
    }, []);

    if(error){
        return (
            <div>
                <div className="logo">
                    <img src={logo} alt="Logo" style={{margin:"auto"}}/>
                </div>
                {/* <p className="error">{error}</p> */}
                <p className="error">Maintenance en cours... Veuillez réessayer plus tard. En cas de problème, contactez le 0647062680</p>
            </div>
            )
    }
        return(
            <>
                <div className="logo">
                    <img src={logo} alt="Logo" style={{margin:"auto"}}/>
                    <PointDeCollecteData/>
                </div>
                <div>
                    <Routes>
                        <Route path="/scan-resultat" element={<ScanResultat/>}/>
                        <Route path="/scan" element={<ScanPage/>}/>
                        <Route path="/" element={<Accueil/>}/>
                    </Routes>
                </div>
            </>
        )
}
