import "./Components.css"
import { gql, useQuery } from '@apollo/client'

const AUTHENICATE_PDC = gql`
  {
    authenticatePointDeCollecte {
        id
        nom
    }
  }
`;

export const PointDeCollecteData = () => {
    const { loading, error, data } = useQuery(AUTHENICATE_PDC,{ errorPolicy: "all" });

    if (error){
        localStorage.removeItem('urlJwt');
        window.location.replace("/");
        return <p className="error">Vous n'êtes pas authentifié...</p>
    };
    if (loading) return <p>Chargement des données...</p>;

    return (
        <div>
            <p className='user-infos'>
                ID : {data.authenticatePointDeCollecte.id}
            </p>
            <p className='user-infos'>
                Nom : {data.authenticatePointDeCollecte.nom}
            </p>
        </div>
    );
}