import "./ScanResultat.css";
import valide from '../Images/valide.svg';
import {useLocation,Link} from 'react-router-dom';

export const ScanResultat = () => {
    const {state} = useLocation();
    console.log("STATE ->",state)
    return(
        <div className="scan-resultat-container">
            <h1>Scan Résultat</h1>
            <img src={valide} className="valide-image" alt="check"/>
            <p>Vous avez <span className="bold">{state.scanConteneur.length} caisses</span> en attentes de récupération</p>
            <Link to="/scan" className="link_scan">
                <button className="button-with-gradient-border">
                    Scanner
                </button>
            </Link>
        </div>
    )
}