import {useState} from 'react'
import { QrReader } from 'react-qr-reader';

import "./Components.css"




export const Scanner = ({handleScan}) => {
    
    return (
        <div className='scan_wrapper'>
            <QrReader
                onResult={(result, error) => handleScan(result,error)}
                style={{ width: '100%' }}
                scanDelay={1000}
                constraints={{
                    facingMode: 'environment',
                }}
            />
        </div>
    );
}