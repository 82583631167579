import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient,createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter as Router } from 'react-router-dom';
import { MyRoutes } from './Routes';
import "./App.css"
// require('dotenv').config();

let httpLink;
if(process.env.NODE_ENV === 'production'){
  console.log('Production mode');
  httpLink = createHttpLink({
    uri: "https://api.suivezlaconsigne.fr/graphql",
  });
}else{
  console.log('Development mode');
  httpLink = createHttpLink({
    uri: 'http://localhost:4000/graphql',
  });
}




const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('urlJwt');
  console.log('FETCHED TOKEN :',token)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <MyRoutes />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);