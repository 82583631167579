import "./Accueil.css";
import indication from "../Images/indication_scan.svg";
import { Link } from "react-router-dom";

export const Accueil = () => {
    return (
        <div className="home_container">
            <div className="center-childs">
                <p className="paragraphe_explicatif"><span className="bold">Scannez les caisses pleines</span> pour informer le transporteur</p>
            </div>

            <div className="indication_container">
                <img src={indication} alt="indication_scan" className="indication_img" />
            </div>
            <p className="legende">Les QR codes se trouvent en bas à droite des faces des caisses</p>
            <Link to="/scan" className="link_scan">
                <button className="button-with-gradient-border">
                    Scanner
                </button>
            </Link>
        </div>
    )
}


