import "./Scan.css"
import "./Accueil.css";

import indication from "../Images/indication_scan.svg";
import { Scanner } from "../Components/Scanner"
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const SCAN_CONTENEUR = gql`
  mutation($codeBarre: String!){
    scanConteneur(codeBarre: $codeBarre) {
        id  
    }
}
`;

export const ScanPage = () => {
    const [scanToApi, { data, loading, error }] = useMutation(SCAN_CONTENEUR,{ errorPolicy: "all" });
    const navigate = useNavigate();


    const handleGoToScanResultat = (data) => {
        navigate("/scan-resultat",{state:data});
    }

    const handleScan = (result,error) => {
        if (result) {
            scanToApi({variables: {codeBarre: result.text}})
        }

        if (error) {
            console.info(error);
        }
    }

    if(data){
        handleGoToScanResultat(data)
    }

    // if(loading){
    //     return(
    //         <p style={{marginTop:50,textAlign:"center"}}>Chargement des données...</p>
    //     )
    // }

    return(
        <div className="scan_page_container">
            <Scanner handleScan={handleScan}/>
            {loading && <p className="bold">Chargement des données...</p>}
            {error && <p className="error">Erreur lors du scan : {error.message}</p>}
            <img src={indication} alt="indication_scan" className="indication_img_scan"/>
        </div>
    )
}