export const fetchJWT = (setError) => {
    let urlJwt = null;

    if(window.location.href.split('?').length >1){
      urlJwt = window.location.href.split('?')[1].split("=")[1]
    }

    if(urlJwt){
      return localStorage.setItem('urlJwt', urlJwt)
    }

    if(!urlJwt && localStorage.getItem('urlJwt')){
      return
    }

    if((!urlJwt) && (!localStorage.getItem('urlJwt'))){
      return setError("Vous n'êtes pas connecté")
    }
}